import React from "react";
import Header from "../components/header";

import {Helmet} from "react-helmet";
import "../styles/index.css";
import {StaticImage} from "gatsby-plugin-image";


function Index() {
    return (<main>
        <Helmet>
            <title>About Me</title>
        </Helmet>

        <Header/>

        <section class="bg-me">
            <div class="container">
                <div class="row">
                    <div className="col-lg-3 order-2 order-lg-1">
                        <StaticImage
                            src="../images/card-thumb-about.png"
                            alt="Photo of Christian Briggs"
                            placeholder="blurred"
                            className="img-fluid"
                        />
                    </div>
                    <div class="col-lg-9 order-1 order-lg-1">
                        <h1 class="text-light">About Me</h1>
                    </div>

                </div>
            </div>
        </section>

        {/* <!-- content --> */}

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">

                        <h2>Hello there</h2>

                        <p>My name is Christian Briggs. </p>

                        <p>I am a multi-disciplinary designer who loves creating digital products with amazing
                            teams. I was born in Massachusetts before the Web was a thing, but when it <i>did</i> become
                            a thing, i started creating and publishing 56 kilobits of digital stuff every second.</p>

                        <p>You can download my PDF resume <a
                            href="/images/christian-briggs-resume-public.pdf" target="_blank">here</a> or contact me
                            on <a href="https://www.linkedin.com/in/christianbriggs/">LinkedIn.</a></p>

                        <p>Here are some very kind things former colleagues have
                            said about me:</p>

                        <div className="row">
                            <blockquote className="blockquote col-lg-4">
                                <p className="mb-0 ">"Humble and witty, Christian‘s presence in a team is sure to
                                    brighten up even the dullest day. With his ability to speak in multiple
                                    languages—design, dev
                                    and business—I envy the team that he’s a part of."</p>
                                <footer className="blockquote-footer">Former colleague</footer>
                            </blockquote>

                            <blockquote className="blockquote col-lg-4">
                                <p className="mb-0">"He brings his imaginative but deeply grounded mind to bear on any
                                    subject, often resulting in visionary ideas."</p>
                                <footer className="blockquote-footer">Former colleague</footer>

                            </blockquote>
                            <blockquote className="blockquote col-lg-4">
                                <p className="mb-0">"His knowledge and enthusiasm inspires others, and he is a lot of
                                    fun to
                                    work with."</p>
                                <footer className="blockquote-footer">Former colleague</footer>
                            </blockquote>

                        </div>

                        <div className="row">
                            <blockquote className="blockquote col-lg-4">
                                <p className="mb-0">"I have had the pleasure of working with Christian for more than 1.5
                                    years, and I'm more and more amazed by his skills. Broad-minded, user-centered UX/UI
                                    designer - his exceptional workflow, skills, ingenuity and comprehension of change
                                    requests made every project a dream."</p>
                                <footer className="blockquote-footer">Former colleague</footer>

                            </blockquote>

                            <blockquote className="blockquote col-lg-4">
                                <p>" He possesses a deep knowledge of UX Design, and his communication
                                    skills are second to none. I'm always impressed by his caliber and his ability to
                                    bring
                                    ideas to life. A highly innovative and professional UX design expert."</p>
                                <footer className="blockquote-footer">Former colleague</footer>

                            </blockquote>

                            <blockquote className="blockquote col-lg-4">
                                <p>"Christian is patient, empathetic and an amazing team player."</p>
                                <footer className="blockquote-footer">Former colleague</footer>

                            </blockquote>

                        </div>


                    </div>

                </div>
            </div>

        </section>

        {/* <!-- end content --> */}
    </main>);
}


export default Index;
